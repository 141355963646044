import React, { useRef, useEffect } from 'react'

import styled, { createGlobalStyle } from 'styled-components'
import PageTemplate from '../components/PageTemplate'

import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import blog_hero_one from '../images/blog/ai-issue-trap.jpg'
import blog_hero_two from '../images/blog/chaotic-world.jpg'
import blog_hero_three from '../images/blog/biden-order.jpg'
import blog_hero_four from '../images/blog/healthcare.jpg'
import blog_hero_five from '../images/blog/fellowship.jpeg'
import blog_hero_six from '../images/blog/game-dev.jpg'
import blog_hero_seven from '../images/blog/master-brand.jpg'
import blog_hero_eight from '../images/blog/ddg_modernworld.png'
import blog_hero_nine from '../images/blog/riseof_diy.png'
import blog_hero_ten from '../images/blog/emotion_ai.png'
import blog_hero_eleven from '../images/blog/watch_leaders3.png'
import blog_hero_twelve from '../images/blog/watch_leaders2.png'
import blog_hero_thirteen from '../images/blog/watch_leaders1.png'
import blog_hero_fourteen from '../images/blog/stories_statistics.png'
import ipgForges from '../images/blog/ipg_forges.jpg'
import ipgRelease from '../images/blog/ipg_realease.jpg'
import paidSocial from '../images/blog/paid_social.jpg'
import IPG_Health_Campaigns from '../images/blog/IPG_Health_Campaigns.jpeg'
import IPG_SXSW from '../images/blog/IPG_SXSW.png'
import Crafting_Stories from '../images/blog/Crafting_Stories.jpeg'
import Health_Wellness_Outlook from '../images/blog/Health_Wellness_Outlook.jpg'
import IPG_Health_Adobe_Summit from '../images/blog/IPG_Health_Adobe_Summit.jpg'

gsap.registerPlugin(ScrollTrigger)

const GlobalStyles = createGlobalStyle`
  body {
    background-color: #001b33;
  }
`
const PageContent = styled.div`
    position: relative;
    z-index: 1;
    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        padding-top: 135px;
    }
`
const PageContentInner = styled.div`
    padding: 0;

    .text-right {
        justify-content: flex-end !important;
        text-align: right !important;
    }
`
const CopyContainer = styled.div`
    width: 100%;
    margin-bottom: 23%;

    h1 {
        color: ${(props) => props.theme.blueDark};
        font-weight: 900;
        font-size: 2.1875rem;
    }

    p {
        color: ${(props) => props.theme.blueDark};
        line-height: 1.75;
        padding-right: 35px;

        span {
            font-weight: 900;
            display: block;
            margin-top: 1rem;
            width: 60%;
        }
    }

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
        margin-bottom: 45px;
        padding-left: ${(props) => props.theme.paddingSidesDesktop};

        p {
            font-size: 1.1rem;
            margin-bottom: 0;
            width: 100%;

            span {
                display: inline;
                margin-top: 0;
                width: auto;
            }
        }

        .bannerHeader {
            width: 70%;
        }
    }

    @media (min-width: ${(props) => props.theme.breakpointDesktop}) {
        h1 {
            font-size: 2.875rem;
        }
    }

    @media (max-width: 440px) {
        margin-bottom: 10%;
    }
`
const BackgroundContent = styled.div`
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #cddbe7, #fff);

    border-bottom-width: 2px;
    border-bottom-color: white;
    border-bottom-style: solid;
    -webkit-box-shadow: 0 0 30px 10px rgba(151, 200, 230, 1);
    box-shadow: 0 0 30px 10px rgba(151, 200, 230, 1);

    @media (min-width: ${(props) => props.theme.breakpointTablet}) {
    }
`

const ArticleDivider = styled.div`
    display: block;
    width: 43.75rem;
    height: 0.125rem;
    background-color: white;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 2;

    @media (max-width: 700px) {
        width: 22rem;
        background-position: right !important;
    }
`

const ArticleContainer = styled.div`
    width: 100%;
    height: 46rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;

    .text-right {
        justify-content: flex-end !important;
        text-align: right !important;

        .article-btn {
            float: right !important;
            margin-top: 0rem;
        }
    }

    .text-left {
        text-align: left;
    }

    .hide {
        opacity: 0 !important;
    }

    :first-child {
        .article-divider {
            opacity: 0;
        }
    }
`

const ArticleCopyWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 46rem;
    background-color: black;
    opacity: 0;
    z-index: 2;
`

const ArticleCopy = styled.div`
    width: 70%;
    color: white;
    z-index: 3;
    padding: 0rem 7rem;
    align-self: center;
    opacity: 0;

    h2 {
        font-size: 4.25rem;
        line-height: 4.688rem;
        margin-bottom: 1rem;
    }

    p {
        font-size: 1.125rem;
        font-weight: 500;
        margin: 0rem 0rem 1rem 0rem;
    }

    p.date-author {
        font-weight: 100;
        margin-bottom: 2rem;
    }

    .article-btn {
        background-color: #deeefd;
        border: none;
        color: #003767;
        font-size: 1.125rem;
        font-weight: bold;
        border-radius: 0.313rem;
        width: 15rem;
        height: 3.125rem;
        margin-top: 1rem;
        overflow: hidden;
        position: relative;

        .fill {
            position: relative;
            width: 100%;
            background-color: #0079c1;
            height: 100%;
            top: 0rem;
            left: -15rem;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            text-align: center;
        }
    }

    @media (max-width: ${(props) => props.theme.breakpointDesktop}) {
        width: 85%;
    }

    @media (max-width: ${(props) => props.theme.breakpointTablet}) {
        padding: 0rem 3rem;
        width: 100%;
    }

    @media (max-width: 700px) {
        h2 {
            font-size: 2rem;
            line-height: 1.2;
        }
    }
`

const articleList = [
    {
        title: 'IPG Health @ Adobe Summit 2024',
        description:
            "Read key takeways from IPG Health experts who attended the Adobe Summit where AI's role in content creation was a focus topic.",
        author: '',
        date: 'April 12, 2024',
        image: IPG_Health_Adobe_Summit,
        link: 'https://ipghealth.com/news/ipg-health-adobe-summit-2024',
    },
    {
        title: '2024 Health & Wellness Outlook',
        description: 'Review five themes Golin sees across the health and wellness landscape for 2024.',
        author: '',
        date: 'April 2, 2024',
        image: Health_Wellness_Outlook,
        link: 'https://golin.com/2024/04/04/what-were-seeing-in-wellness-2024',
    },
    {
        title: 'Crafting Stories and Shaping the Future of Healthcare',
        description: 'Explore thought-provoking considerations for effectively integrating AI in healthcare.',
        author: '',
        date: 'March 26, 2024',
        image: Crafting_Stories,
        link: 'https://ipghealth.com/news/crafting-stories-and-shaping-the-future-of-healthcare',
    },
    {
        title: 'IPG Health at SXSW 2024: Insights for Healthcare Marketers',
        description:
            'Uncover invaluable insights and key takeaways for the health marketing industry — from AI to health equity, digital health and beyond.',
        author: '',
        date: 'March 25, 2024',
        image: IPG_SXSW,
        link: 'https://ipghealth.com/news/ipg-health-at-sxsw-2024-insights-for-healthcare-marketers',
    },
    {
        title: 'IPG Health Campaigns Accelerating Health for Women Around the World',
        description: 'Explore some of our groundbreaking campaigns and the impact they’ve had on women’s health.',
        author: '',
        date: 'March 21, 2024',
        image: IPG_Health_Campaigns,
        link: 'https://ipghealth.com/news/ipg-health-campaigns-accelerating-health-for-women-around-the-world',
    },
    {
        title: 'An Outlook on Healthcare Communications from #SXSW24',
        description:
            "Uncover three unique perspectives with the potential for immediate, tangible impact to your brand's project planning.",
        author: '',
        date: 'March 20, 2024',
        image: blog_hero_six,
        link: 'https://golin.com/2024/03/25/an-outlook-on-healthcare-communications-from-sxsw24',
    },
    {
        title: 'Paid Social Campaigns Are Important to Corporate Pharma Success But Face New Scrutiny in an Election Year',
        description:
            'Discover five steps your brand can take to ensure your social ads meet requirements and are seen by their intended audiences.',
        author: '',
        date: 'March 6, 2024',
        image: paidSocial,
        link:
            'https://golin.com/2024/03/06/paid-social-campaigns-are-important-to-corporate-pharma-success-but-face-new-scrutiny-in-an-election-year',
    },
    {
        title: 'IPG Releases 9th Annual ESG Report',
        description:
            "Download the report on IPG's five strategic priorities: diversity, equity and inclusion, human capital, climate action, responsible media and content, and data ethics and privacy.",
        author: '',
        date: 'March 6, 2024',
        image: ipgRelease,
        link: 'https://esg.interpublic.com',
    },
    {
        title: 'IPG Forges First-to-Market Partnership With Adobe',
        description:
            'Learn about IPG Engine, the first integrated marketing platform powered by Adobe GenStudio, using AI and data to automate and scale creative content.',
        author: '',
        date: 'February 29, 2024',
        image: ipgForges,
        link:
            'https://investors.interpublic.com/news-releases/news-release-details/ipg-forges-first-market-partnership-adobe-revolutionize-content',
    },
    // 1
    {
        title: 'Leading Amid a Changing and Chaotic World',
        description:
            "Explore Weber Shandwick's innovative thought leadership series, 'Leading at the Intersections 2024.' Uncover insights into the complex challenges facing today's global leaders and businesses across various sectors.",
        author: '',
        date: 'January 8, 2024',
        image: blog_hero_two,
        link: 'https://webershandwick.com/news/leading-at-the-intersections-in-2024-leading-amid-a-changing-and-chaotic-world',
    },
    // 2
    {
        title: `Diversifying Clinical Trials: "It's a Win-Win for All"`,
        description:
            "In Pharma Live's assessment of the state of diversity in clinical trials highlights IPG Health's clinical trials diversity offering featuring IPG Health's Chief Medical Officer Sommer Bazuro.",
        author: '',
        date: 'January 3, 2024',
        image: blog_hero_eight,
        link:
            'https://ipghealth.com/news/ipg-health-s-chief-medical-officer-sommer-bazuro-on-diversifying-clinical-trials-it-s-a-win-win-for-all',
    },
    // 3
    {
        title: `The AI Issues Trap`,
        description: `Delve into the intersection of AI and crisis management with Golin's "The AI Issues Trap" report. Navigate digital challenges, misinformation risks, and learn how to stay ahead in this ever-evolving landscape."`,
        author: '',
        date: 'December 19, 2023',
        image: blog_hero_one,
        link: 'https://golin.com/2023/12/19/the-ai-issues-trap-report-understanding-modern-issues-management',
    },
    // 4
    {
        title: `Media Evolution in Pharma`,
        description: `Explore the future of Pharma marketing with Initiative Health's Harshit Jain MD and Richard Springham. Learn how digital channels are transforming the media landscape and revolutionizing HCP engagement strategies.`,
        author: '',
        date: 'December 19, 2023',
        image: blog_hero_nine,
        mediaLink: 'https://www.youtube.com/watch?v=DGnr_VA8uP4&t=7s',
    },
    // 5
    {
        title: `Building Clarity and Strategy Around AI`,
        description: `Join Chris Perry from Weber Shandwick Futures as he explores the role of AI in marketing communications, emphasizing judgment in AI usage and the future impact of AI agents interacting. Get a glimpse into how AI could reshape influence dynamics by 2024.`,
        author: '',
        date: 'December 11, 2023',
        image: blog_hero_ten,
        link: 'https://www.interpublic.com/about/collective-intelligence/building-clarity-and-strategy-around-ai/',
    },
    // 6
    {
        title: `The Resurgence of the RX Master Brand`,
        description: `Delve into the fascinating resurgence of the Rx master brand, exploring how pharmaceutical corporations are shifting from product-level branding to a unified corporate identity, thereby enhancing their brand value and presence in an increasingly competitive market.`,
        author: '',
        date: 'November 30, 2023',
        image: blog_hero_seven,
        link: 'https://ipghealth.com/news/the-resurgence-of-the-rx-master-brand',
    },
    // 7
    {
        title: `Key Takeaways From ADCOLOR 2023`,
        description: `Uncover key insights from the ADCOLOR 2023 conference, where industry leaders discussed the importance of vision, advocacy, and leadership in promoting diversity and inclusion, the role of AI and consumer research in advertising, and the journey to executive positions in creative industries.`,
        author: '',
        date: 'November 29, 2023',
        image: blog_hero_eleven,
        link: 'https://ipghealth.com/news/vision-advocacy-and-leadership-key-takeaways-from-adcolor-2023',
    },
    // 8
    {
        title: `How Will President Biden’s Executive Order on Artificial Intelligence (AI) Impact the Healthcare Industry?`,
        description: `Discover the potential impact of President Biden's Executive Order on AI in transforming healthcare, from improving diagnostics to streamlining drug development,`,
        author: '',
        date: 'November 3, 2023',
        image: blog_hero_three,
        link:
            'https://ipghealth.com/news/how-will-president-biden-s-executive-order-on-artificial-intelligence-ai-impact-the-healthcare-industry',
    },
    // 9
    {
        title: `AI and Healthcare: What's Next?`,
        description: `Dive into the world of AI and healthcare with Tim Callington and Patrick Chaupham. They share insights from North America and Europe, highlighting key success factors and risks. Expand your understanding with this engaging discussion.`,
        author: '',
        date: 'November 1, 2023',
        image: blog_hero_four,
        mediaLink: 'https://www.youtube.com/watch?v=FTUGV2ad2IY',
    },
    // 10
    {
        title: `Results From Golin's Inaugural Fellowship Program for Future Black Leaders`,
        description: `Discover the impact of Golin's inaugural PRep School fellowship program. See how students from HBCUs and BGLOs are offering fresh perspectives to Golin's clients and shaping the future of PR.`,
        author: '',
        date: 'October 18, 2023',
        image: blog_hero_five,
        link:
            'https://golin.com/2023/10/18/golins-inaugural-fellowship-program-for-future-black-leaders-results-in-impactful-client-creative-from-its-first-graduation-class/',
    },
    // 11
    {
        title: `Breaking the Barrier of Climate Communication`,
        description: `Explore a MAGNA survey on climate communication, revealing how advertisers can better express their sustainability goals. Discover strategies for brands to overcome consumer barriers to sustainable living and the need for transparent data in advertising.`,
        author: '',
        date: 'October 10, 2023',
        image: blog_hero_twelve,
        link: 'https://www.interpublic.com/about/collective-intelligence/breaking-the-barrier-of-climate-communication/',
    },
    // 12
    {
        title: `Truth Central: The Truth About Work`,
        description: `In the AI era with 'Truth About Work,' is a comprehensive study by McCann Worldgroup Truth Central. This research provides crucial insights into employee engagement strategies, AI's impact, and the role of brand purpose in employee involvement. It uncovers the fears, hopes, and aspirations of modern employees across 18 markets.`,
        author: '',
        date: 'October 1, 2023',
        image: blog_hero_thirteen,
        link: 'https://cms.mccannworldgroup.com/wp-content/uploads/2023/10/TAWork_ExecutiveSummary.pdf',
    },
    // 13
    {
        title: 'Truth Central: The Truth About Modern Families',
        description:
            'Explore the dynamic evolution of parenting and family structures, diving into the changes in what it means to build a family, with or without children. Based on over a decade of research, the study reveals 10 exciting conversations identified in 2023, as families worldwide navigate new complexities.',
        author: '',
        date: 'August 1, 2023',
        image: blog_hero_fourteen,
        link: 'https://cms.mccannworldgroup.com/wp-content/uploads/2023/08/MW_TAModernFamilies_V3_ExecutiveSummary.pdf',
    },
    // 14
    {
        title: `What Health Tech Can Learn From Game Developers: Human-Centered Design’s Important Role in Health’s Future`,
        description: `Immerse yourself in an intriguing exploration of how game developers' strategies can revolutionize health tech, as explained by Dr. Lyle Berkowitz. Discover the potential of gamification in enhancing patient engagement and transforming healthcare outcomes.`,
        author: '',
        date: 'March 16, 2023',
        image: blog_hero_six,
        link: 'https://rga.com/futurevision/articles/dr-lyle-berkowitz-what-health-tech-can-learn-from-game-developers',
    },
]

function InnovationsPage() {
    useEffect(() => {
        gsap.utils.toArray('.article-container').forEach((section) => {
            let wrapper = section.querySelector('.article-copy-wrapper'),
                copy = section.querySelector('.article-copy'),
                btn = section.querySelector('.article-btn'),
                fill = section.querySelector('.fill'),
                btnCopy = section.querySelector('.btn-copy')

            const articleReveal = gsap
                .timeline({ paused: true })
                .to(copy, { opacity: 1, duration: 1, stagger: 0.3 })
                .to(wrapper, { opacity: 0.8, duration: 1, stagger: 0.3 }, 0)

            ScrollTrigger.create({
                trigger: section,
                start: 'top 40%',
                onEnter: () => articleReveal.play(),
                onLeaveBack: () => articleReveal.reverse(),
            })

            const btnHover = gsap
                .timeline({ paused: true })
                .to(fill, { left: `${0}rem`, duration: 1 })
                .to(btnCopy, { color: 'white', duration: 1 }, 0)

            btn.addEventListener('mouseover', () => {
                btnHover.play()
            })

            btn.addEventListener('mouseout', () => {
                btnHover.reverse()
            })
        })
    })

    return (
        <PageTemplate metaDataIndex={5} selectedNavIndex={5}>
            <GlobalStyles />
            <PageContent>
                <BackgroundContent></BackgroundContent>
                <PageContentInner role="main" id="main">
                    {articleList.map((article, index) => (
                        <ArticleContainer
                            style={{ backgroundImage: `url(${article.image})` }}
                            className={`article-container ${Math.abs(index % 2) == 1 ? 'text-right' : 'text-left'}`}
                        >
                            <ArticleCopy className={`article-copy ${Math.abs(index % 2) == 1 ? 'text-right' : 'text-left'}`}>
                                <h2>{article.title}</h2>
                                <p>{article.description}</p>
                                <p className="date-author">
                                    {article.date}
                                    {article.author ? ` | ${article.author}` : ''}
                                </p>
                                <a href={article?.link || article.mediaLink} target="_blank" title={article.title}>
                                    <div className="article-btn">
                                        <div className="fill"></div>

                                        <span className="btn-copy">
                                            {article?.link && 'Read the Full Article'}
                                            {article?.mediaLink && 'Watch the Full Video'}
                                        </span>
                                    </div>
                                </a>
                            </ArticleCopy>
                            <ArticleCopyWrapper className="article-copy-wrapper"></ArticleCopyWrapper>
                            <ArticleDivider className="article-divider"></ArticleDivider>
                        </ArticleContainer>
                    ))}
                </PageContentInner>
            </PageContent>
        </PageTemplate>
    )
}

export default InnovationsPage
